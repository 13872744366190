import { useState } from "react";
import { util } from "../../../_helpers";
import { Field, SurveyFormModel } from "../../../_models";
import { InputRichText } from "./InputRichText";
import { InputSelect } from "./InputSelect";
import { InputTag } from "./InputTag";

var surveyLogoUrl = "";
async function getSurveyLogo(uuid){
  var apiGetLogoUrl = util.getConfigs().surveyGetLogo + uuid;
  surveyLogoUrl = await fetch(apiGetLogoUrl, {
    method: 'GET',
    headers:{
      'Authorization': 'Bearer ' + util.getConfigs().surveyApiToken
    }
  })
  .then(response => {
    if (!response.ok) {
        throw new Error(`Erreur HTTP! Statut: ${response.status}`);
    }
    return response.text();
  })
  .then(data => {
    if(data === "" || data === undefined){
      console.log("DATA IS EMPTY : " + data);
      return "";
    }else{
      // console.log("DATA IS NOT EMPTY " + util.getConfigs().contentServerUrl +`${uuid}/${data}`);
      return util.getConfigs().contentServerUrl +`${uuid}/${data}`;
    }
  })
  .catch(error => {
    console.error('Erreur lors de la récupération du fichier :', error);
    return "";
  });
  return surveyLogoUrl;
}

export const InputField = ({
  field,
  onChange,
  form
}: {
  field: Field;
  onChange: any;
  form?: SurveyFormModel;
}) => {
  const surveyuuid = window.location.search?.split("=")[1];
  const surveyLogo = async () => {
    try{
      field.value = await getSurveyLogo(surveyuuid);
    }
    catch(error){
      console.log("SOME ERROR OCCURED " + error);
    }
  };
  if(field.type === "file"){
    surveyLogo();
  }
  
  const [data, setData] = useState<any>({
    loading: false,
    link: field.type === "file" ? field.value : "",
    showImage: true,
  });
  if (!field) return <div />;
  if (field.type === "select")
    return <InputSelect field={field} onChange={onChange} />;
  if (field.type === "richtext")
    return <InputRichText field={field} onChange={onChange} />;
  if (field.type === "tag")
    return <InputTag field={field} onChange={onChange} />;

  const change = async (e: any) => {
    let val = e.target.value;

    if (field.type === "file") {
      setData({ ...data, loading: true });
      const url = await util.upload(e.target.files[0], surveyuuid);
      
      // debugger;
      var imageUrl = util.getConfigs().contentServerUrl + `${surveyuuid}/${url}`;
      setData({ ...data, loading: false, link: imageUrl });
      // console.log([data, setData]);
    }
    // if(field.type === "text") val === "" ? field.value = "" : field.value = val;  // ligne à supprimer
    if(field.name=="startDate" && val) { onChange("startHour", "00:00"); }
    if(field.name=="endDate" && val) { onChange("endHour", "23:59"); }
    if(field.type != "file") onChange(field.name, val);
  };

  const attr = field.data.inputAttributes || {};
  const val = field.type === "file" ? undefined : field.value;
  const { loading, link: link, showImage } = data;

  return (
    <div
      {...{
        className:
          "form-group " + field.layout + (field.enable ? "" : " disabled"),
      }}
    >
      <label htmlFor={field.name}>
        {field.label}
        {field.required && (
          <i className="required-label" data-placement="right">
            *
          </i>
        )}
        <i
          className="ml-2 fa fa-question-circle"
          style={{ cursor: "pointer" }}
          data-toggle="tooltip"
          data-placement="right"
          title=""
          data-original-title={field.helpText}
        ></i>
      </label>
      <input
        onChange={(e) => change(e)}
        value={val}
        id={field.name}
        placeholder={field.placeHolder}
        type={field.type}
        min={field.minValue}
        className={field.classNames}
        {...attr}
      />
      {field.error && (
        <span style={{ color: "red" }}>{util.getErrorByKey(field.error)}</span>
      )}
      {loading && <span>Chargement ...</span>}
      {link && (
        <a
          href={link}
          style={{ color: showImage ? "red" : "" }}
          onClick={(e) => {
            e.preventDefault();
            setData({
              ...data,
              showImage: !showImage,
            });
          }}
        >
          {showImage ? "Masquer le logo" : "Afficher le logo"}
        </a>
      )}
      {link && showImage && (
        <div style={{ padding: "10px" }}>
          <img
            style={{
              maxHeight: "100px",
              border: "1px solid #ccc",
            }}
            src={link}
          />
        </div>
      )}
    </div>
  );
};
